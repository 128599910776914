<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
      alt="misc-mask"
    />

    <!-- <v-img class="misc-tree" src="@/assets/images/misc/tree-2.png" alt="misc-tree" /> -->

    <div class="page-title text-center px-5">
      <!-- <p class="text-sm">
        {{ t('miscellaneous.you_dont_have_permission') }}!
      </p> -->

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="100"
          :src="appLogo"
          alt="misc-character"
        />
      </div>

      <h2 class="text-sm font-weight-semibold text--primary">
        {{ t('miscellaneous.please_wait') }}
      </h2>

      <!-- <div>
        <h2 class="text-2xl font-weight-semibold text--primary">
          {{ t('miscellaneous.no_video_on_web') }}! 📲
        </h2>

        <div class="d-flex justify-center mt-4 ">
          <v-img
            max-width="100"
            :src="require(`@/assets/images/logos/app-store.png`)"
            alt="misc-character"
            class="mr-4 cursor-pointer"
            @click="openTab('https://apps.apple.com/us/app/fanaty/id1461051554')"
          />

          <v-img
            max-width="100"
            :src="require(`@/assets/images/logos/playstore.png`)"
            alt="misc-character"
            class="ml-4 cursor-pointer"
            @click="openTab('https://play.google.com/store/apps/details?id=com.fanaty')"
          />
        </div>
      </div> -->

      <!-- <v-btn
        color="secondary"
        to="/"
        class="mb-4"
      >
        {{ t('miscellaneous.back_to_home') }}
      </v-btn> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, onMounted, onBeforeMount } from '@vue/composition-api'
import { useRouter, isAndroid, isIOS, openTab } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import themeConfig from '@themeConfig'

export default {
  setup() {
    const { route } = useRouter()
    const refVideo = ref(null)
    const { t } = useUtils()

    onBeforeMount(() => {
      refVideo.value = route.value.params.playback
      if (isAndroid() || isIOS()) {
        if (isIOS()) {
          // window.open().location = `fanaty://video?video_id=${refVideo.value}`
          openTab(`fanaty://video?video_id=${refVideo.value}`)
        } else window.location = `fanaty://video?video_id=${refVideo.value}`
      } else {
        openTab('https://www.fanaty.com')
      }
    })

    onMounted(async () => {
      if (isAndroid() || isIOS()) {
        setTimeout(() => {
          if (isIOS()) {
            window.location.href = 'https://apps.apple.com/us/app/fanaty/id1461051554'
          } else if (isAndroid()) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.fanaty'
          }
        }, 5000)
      }
    })

    return {
      refVideo,

      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      openTab,

      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
